import fileSystem from '~/services/fileSystem'
import type { Image } from '~/services/fileSystem'

const medias = reactive<{ images: Image[] }>({ images: [] })

export default async function useMedias() {
  const { deleteImageFromFolder, fetchImagesFromFolder, saveImageToFolder } =
    await fileSystem()

  const loadImagesFromFolder = async () => {
    medias.images = await fetchImagesFromFolder()
  }

  const saveImage = async (imageURL: string, date: string, id: string) => {
    const image = await saveImageToFolder(imageURL, date, id)
    medias.images.push(image)
  }

  const deleteImage = async (imageObject: Image) => {
    const canDelete = confirm(
      'Cette action est irréversible et tu ne seras pas remboursé du Mana utilisé.',
    )
    if (canDelete) {
      await deleteImageFromFolder(imageObject)
      medias.images = medias.images.filter(
        (image) => image.name !== imageObject.name,
      )
    }
  }

  const getMediasByDate = (date: string): Image[] => {
    return computed(() => {
      return medias.images.filter(({ name }) => name.includes(date))
    }).value
  }

  if (medias.images.length === 0) await loadImagesFromFolder()

  return {
    saveImage,
    deleteImage,
    getMediasByDate,
  }
}
