import type { Image } from './fileSystem'

export default {
  deleteImageFromFolder: (imageObject: Image) => {
    const images = JSON.parse(localStorage.getItem('images') || '[]')
    const updatedImages = images.filter(
      (image: Image) => image.path !== imageObject.path,
    )
    localStorage.setItem('images', JSON.stringify(updatedImages))
  },
  saveImageToFolder: async (imageUrl: string, date: string, id: string) => {
    const image: Image = {
      name: `${date}_${id}`,
      path: imageUrl,
      src: imageUrl,
      thumbnail: imageUrl,
    }
    const images = JSON.parse(localStorage.getItem('images') || '[]')
    images.push(image)
    localStorage.setItem('images', JSON.stringify(images))
    return image
  },
  fetchImagesFromFolder: async () => {
    if (typeof window === 'undefined') {
      return []
    }
    await new Promise((resolve) => setTimeout(resolve, 200))
    const images = JSON.parse(localStorage.getItem('images') || '[]')
    return images
  },
}
